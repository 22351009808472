$('.linkScroll').on('click', function() {
    goToSection__scroll($('#inovadora'), -50)
});

$('.link_inscricao').on('click', function(event) {
    event.preventDefault();
    goToSection__scroll($('#inscricao'), -50)
});

var swiper__bc = new Swiper('.swiper__bc', {
    speed: 400,
    spaceBetween: 0,
    autoplay: {
        delay: 5000,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        clickable: true,
    },
});